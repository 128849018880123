<template>
  <div class="productCenter">
    <gt-header checked="cpzx"></gt-header>
    <banner></banner>
    <router-view></router-view>
    <gt-footer></gt-footer>
  </div>
</template>

<script>
import GtHeader from "../../components/gtHeader";
import GtFooter from "../../components/gtFooter";

import { isInViewPort } from "../../mixin/mixin";
import Banner from "../../components/nProductCenter/banner";
import AiSystem from "../../components/nProductCenter/aiSystem";

export default {
  name: "productCenter",
  data() {
    return {};
  },
  mixins: [isInViewPort],
  components: {
    AiSystem,
    Banner,
    GtFooter,
    GtHeader,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.productCenter {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    height: 100px;
    background: rgba(167, 198, 156, 0.3);
  }
}

.cultureLine {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  span {
    font-size: 20px;
  }
}
.greenBox {
  width: 410px;
  height: 10px;
  background: #a7c69c;
}
.greenTxt {
  position: relative;
  width: 380px;
  font-size: 30px;
  text-align: center;
  top: -18px;
}
.cultureMain {
  margin: 0 auto;
  width: 950px;
  font-size: 14px;
  color: #999999;
}
.toTop {
  position: fixed;
  bottom: 200px;
  right: 200px;
  width: 60px;
  height: 60px;
  background: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .topArrow {
    cursor: pointer;
    color: white;
    font-size: 40px;
  }
}
</style>
