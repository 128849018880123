<template>
<div class="aiSystem">
  <div class="titleLine">

    <div class="lineTwo">
      <img src="../../assets/newImgs/home/leftsjx.png" alt="">
      <div>AI食安溯源检测系统（标准版）</div>
      <img src="../../assets/newImgs/home/rightsjx.png" alt="">
    </div>
    <div class="lineThree">
      AI Food Traceability and Safety Solution
    </div>
  </div>
  <div class="mainCenter">
    <div class="boxEver">
      <div class="boxImg">
        <el-popover placement="right" trigger="hover">
          <div class="boxSign">
              <div class="sign-item1">AI Food Traceability and Safety Solution</div>
              <div class="sign-item2">smart bluetooth weighing</div>
              <div class="sign-item3">标准化验收，把控食材质量</div>
              <div class="sign-item3">食材溯源涉疫食材自动警示，防疫常态化</div>
            </div>
        <img src="../../assets/newImgs/nProductCenter/bigMachine.png" slot="reference"/>
        </el-popover>
      </div>
      <div class="boxTitle">Ai食安溯源系统</div>
      <div class="boxTxt">AI Food Traceability<br/>
        and Safety Solution</div>
    </div>
    <div class="boxEver">
      <div class="boxImg">
        <el-popover placement="right" trigger="hover">
            <div class="boxSign">
              <div class="sign-item1">智慧数据大屏</div>
              <div class="sign-item2">Smart Data Screen</div>
              <div class="sign-item3">数据自动采集 汇总分析，高效展示 清晰明了</div>
              <div class="sign-item3">对接食安平台数据，涉疫食材自动预警</div>
            </div>
        <img src="../../assets/newImgs/nProductCenter/bigScreen.png" slot="reference">
       </el-popover>
      </div>
      <div class="boxTitle">智慧数据大屏
        </div>
      <div class="boxTxt">Smart Data Screen</div>
    </div>
    <div class="boxEver">
      <div class="boxImg">
         <el-popover placement="right" trigger="hover">
            <div class="boxSign">
              <div class="sign-item1">后台管理系统</div>
              <div class="sign-item2">Backstage Management System</div>
              <div class="sign-item3">自动采集数据，综合信息处理</div>
              <div class="sign-item3">快捷搜索简单易用，信息数据一键导出</div>
            </div>
        <img src="../../assets/newImgs/nProductCenter/behindSystem.png"   slot="reference">
      </el-popover>
      </div>
      <div class="boxTitle">后台管理系统
      </div>
      <div class="boxTxt">Backstage Management System<br/>
        System</div>
    </div>
    <div class="boxEver">
       <div class="boxImg">
        <el-popover placement="left" trigger="hover">
       
          <div class="boxSign">
              <div class="sign-item1">后勤管理应用程序</div>
              <div class="sign-item2">Logistics Management App</div>
              <div class="sign-item3">功能齐全，应用便捷</div>
              <div class="sign-item3">对接食安平台数据，涉疫食材自动预警</div>
            </div>
          <img src="../../assets/newImgs/nProductCenter/phone.png" slot="reference"/>
            </el-popover>
        </div>
      <div class="boxTitle">后勤管理应用程序
      </div>
      <div class="boxTxt">Logistics Management App</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "aiSystem"
}
</script>

<style lang="scss" scoped>
.aiSystem {
  width: 1920px;
  height: 1200px;
  background: white;
  padding-top: 90px;
  box-sizing: border-box;
}
.titleLine {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.lineTwo {
  display: flex;
  font-size: 36px;
  color: #333333;
  align-items: center;
  img {
    width: 45px;
  }
}
.lineThree {
  color: #666666;
  font-size: 20px;
  margin-top: 10px;
}

.mainCenter {
  position: relative;
  width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 80px;
  img:hover {
    transform: scale(1.5);
    transition: 0.5s;
  }
}
.boxEver {
  position: relative;
  width: 360px;
  margin-top: 50px;
}
.boxImg {
  width: 360px;
  height: 240px;
  border-radius: 8px;
  background: #a7c69c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    //width: 275px;
    height: 160px;
    transition: 0.5s;
  }
}
.boxTitle {
  font-size: 20px;
  color: #333333;
  margin-top: 20px;
}
.boxTxt {
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
}
.boxImgPhone {
  border-radius: 8px;
  background: #a7c69c;
  width: 360px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  .innerImgs {
    width: 275px;
    height: 160px;
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 78px;
    height: 158px;
    transition: 0.5s;
  }
}
</style>
<style lang="scss">
.boxSign {
   margin: 10px;
  .sign-item1 {
    color: #598948;
    font-size: 20px;
    border-bottom: 1px solid #a7c69c;
    padding-bottom: 4px;
   
  }
  .sign-item2 {
    color: #a7c69c;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .sign-item3{
      margin: 10px 0;
    font-size: 12px;
  }
}
</style>