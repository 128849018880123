<template>
  <div class="banner">
  <img src="../../assets/newImgs/nProductCenter/banner.png">
  </div>
</template>

<script>
export default {
  name: "banner"
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  width: 1920px;
  height: 440px;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
